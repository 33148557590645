import * as React from "react"
import { Link } from "gatsby"
import Logo from "../images/newDesign/logo.svg"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

export default function Header() {
  return (
    <>
      <nav className="navbar navbar-expand-xl bg-light sticky-top container-custom">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={Logo} style={{ width: "252px" }} alt={``} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul
              className="navbar-nav my-2 my-lg-0 navbar-nav-scroll"
              style={{ marginLeft: "auto" }}
            >
              <li className="nav-item nav-item-custom">
                <Link className={`nav-link mr-4`} aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item nav-item-custom">
                <Link className="nav-link" to="/about-indian-esg-network">
                  About Us
                </Link>
              </li>
              <li class="nav-item dropdown nav-item-custom">
                <a
                  class="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  Events
                </a>
                <ul class="dropdown-menu">
                  <li className="nav-item nav-item-custom">
                    {/* <Link
                      className="sub-menu dropdown-item"
                      to="/auroville-programs"
                    >
                      Auroville: ESG Retreat
                    </Link> */}
                    <Link className="sub-menu dropdown-item" to="/delhi-programs">
                    CSC - IESGN SDG Conclave
                    </Link>
                    <Link
                      className="sub-menu dropdown-item"
                      to="/upcoming-events"
                    >
                      Upcoming Events
                    </Link>
                    <Link
                      className="sub-menu dropdown-item"
                      to="/IESG-network-launch"
                    >
                      Indian ESG Network Launch
                    </Link>

                    {/* <Link className="sub-menu dropdown-item" to="/sponsor">
                      Join The Event
                    </Link> */}
                    <Link
                      className="sub-menu dropdown-item"
                      to="/indo-russia-round-table"
                    >
                      Indo Russia Round Table
                    </Link>
                    <Link
                      className="sub-menu dropdown-item"
                      to="/orientation-program"
                    >
                      Orientation Program
                    </Link>
                    <Link
                      className="sub-menu dropdown-item"
                      to="/photoGallery"
                    >
                     Gallery
                    </Link>
                    {/* <Link className="sub-menu dropdown-item" to="/register">
                      Register
                    </Link> */}
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown nav-item-custom">
                <a
                  class="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                >
               Training
                </a>
                <ul class="dropdown-menu">
                  <li className="nav-item nav-item-custom">
                  <Link
                      className="sub-menu dropdown-item"
                      to="/training-programs"
                    >
                      Trainings
                    </Link>
                  <Link
                      className="sub-menu dropdown-item"
                      to="/courses"
                    >
                      Courses
                    </Link>
                </li>
                </ul>
                </li>
                <li className="nav-item nav-item-custom">
                <Link className="nav-link" to="/webinar">
                Webinar
                </Link>
              </li>
             
              <li className="nav-item nav-item-custom">
                <Link className="nav-link" to="/knowledge-repository">
                  Articles
                </Link>
              </li>

              <li className="nav-item nav-item-custom">
                <Link className="nav-link" to="/mediaCoverage">
                  Media Coverage
                </Link>
              </li>
              <li className="nav-item nav-item-custom">
                <Link className="nav-link" to="/join-our-community-form">
                  Join Us
                </Link>
              </li>
              <li className="nav-item nav-item-custom">
                <Link className="nav-link" to="/advertisement">
                  Grow with IESGN
                </Link>
              </li>
              {/* <li className="nav-item nav-item-custom">
                <Link className="nav-link" to="/collaborate">
                  Collaborate
                </Link>
              </li> */}
              {/* <li className="nav-item nav-item-custom">
                <a
                  target="_blank"
                  className="nav-link"
                  href="https://docs.google.com/forms/d/163qqaGlcR3IVjbS5ju-yHE3B4dxQ2fZp0l63wAAneQE/edit"
                >
                  Join IESGN
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
